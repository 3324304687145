
<div *ngIf="title.length > 0" class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="modal-footer justify-content-between" aria-hidden="true">
  <button type="button" class="btn btn-secondary" (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>
